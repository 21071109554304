(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/personalized-storage/assets/javascripts/personalization.js') >= 0) return;  svs.modules.push('/components/components/personalized-storage/assets/javascripts/personalization.js');

(function(svs) {
  'use strict';

  svs = svs || {};
  svs.components = svs.components || {};

  svs.components.personalized_storage = (function() {
    var personalizedDomainsKeys = [
      { domain: '*', key: 'correction_mail_signup_showed' }
    ];
    var log = new svs.core.log.Logger('svs.components.personalized_storage');
    var lsPersonalizationDomain = 'svs.player.settings';
    var lsPersonalizationKey = 'personalizedContentSvs';
    var personalizationLsCacheTimeMinutes = 10;

    function isPersonalizationOn(callback) {
      if (!svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
        log.debug('Not logged in, can not check the setting personalizedContentSvs, return true for personalization');
        if (typeof callback === 'function') {
          callback(true);
        } else {
          return true;
        }
      }

      svs.components.Storage.browser.get(
        lsPersonalizationKey,
        lsPersonalizationDomain,
        function(personalizationLs) {
          if (personalizationLs !== undefined) {
            log.debug('Found personalization setting in localstorage with value: ' +
              personalizationLs);
            if (typeof callback === 'function') {
              callback(personalizationLs);
            } else {
              return personalizationLs;
            }
          } else {
            svs.core.jupiter.call(
              {
                method: 'GET',
                path: '/player/2/customizedsettings'
              },
              function(resp) {
                var personalizationSettings =
                  resp &&
                    resp.customizedSettings &&
                    resp.customizedSettings.properties &&
                    resp.customizedSettings.properties
                      .personalizedContentSvs === '1' ||
                  false;
                log.debug('Fetched personalization setting from player/customizedsettings with value: ' +
                  personalizationSettings);
                svs.components.Storage.browser.set(
                  lsPersonalizationKey,
                  lsPersonalizationDomain,
                  personalizationSettings,
                  personalizationLsCacheTimeMinutes,
                  undefined
                );
                if (typeof callback === 'function') {
                  callback(personalizationSettings);
                } else {
                  return personalizationSettings;
                }
              },
              function() {
                log.debug('Could not fetched personalization setting from player/customizedsettings');
                if (typeof callback === 'function') {
                  callback(true);
                } else {
                  return true;
                }
              }
            );
          }
        }
      );
    }

    function inPersonalizedDomainsKeys(key, domain) {
      var foundInPersonalizedDomainsKeys;

      isPersonalizationOn(function(personalization) {
        if (!personalization) {
          log.debug('Personalization is off. Check in the list of personalized items if domain/key is blocked');
          foundInPersonalizedDomainsKeys = _.find(
            personalizedDomainsKeys,
            function(personalizedDomainsKeysObj) {
              if (personalizedDomainsKeysObj.domain === '*') {
                return personalizedDomainsKeysObj.key === key;
              } else if (personalizedDomainsKeysObj.key === '*') {
                return personalizedDomainsKeysObj.domain === domain;
              }
              return (
                personalizedDomainsKeysObj.domain === domain &&
                  personalizedDomainsKeysObj.key === key
              );
            }
          );

          if (foundInPersonalizedDomainsKeys) {
            log.debug('Found key: ' +
              key +
              ' and domain: ' +
              domain +
              ' in the list of personalized items, removes it from local storage');
            svs.components.Storage.browser.remove(key, domain);
            return true;
          }
          log.debug('Did not find key: ' +
              key +
              ' and domain: ' +
              domain +
              ' in the list of personalized items');
          return false;
        }
        log.debug('Personalization is on');
        return false;
      });
    }

    function clearPersonalizedDomainsKeysFromLS() {
      var lsKeys = Object.keys(localStorage);

      log.debug('Clear all items in local storage which is in the list of personalized items');
      _.each(personalizedDomainsKeys, function(personalizedDomainsKeysObj) {
        if (personalizedDomainsKeysObj.domain === '*') {
          _.each(lsKeys, function(lsKey) {
            if (new RegExp(personalizedDomainsKeysObj.key + '$').test(lsKey)) {
              var tmpDomain = lsKey.substring(
                0,
                lsKey.indexOf(personalizedDomainsKeysObj.key) - 1
              );
              svs.components.Storage.browser.remove(tmpDomain + '.' + personalizedDomainsKeysObj.key);
            }
          });
        } else if (personalizedDomainsKeysObj.key === '*') {
          _.each(lsKeys, function(lsKey) {
            if (
              new RegExp('^' + personalizedDomainsKeysObj.domain).test(lsKey)
            ) {
              var tmpKey = lsKey.substring(personalizedDomainsKeysObj.domain.length + 1);
              svs.components.Storage.browser.remove(personalizedDomainsKeysObj.domain + '.' + tmpKey);
            }
          });
        }
      });
    }

    function get(key, domain, callback) {
      if (inPersonalizedDomainsKeys(key, domain)) {
        return undefined;
      }
      return svs.components.Storage.browser.get(key, domain, callback);
    }

    function set(key, domain, payload, expires, callback) {
      if (inPersonalizedDomainsKeys(key, domain)) {
        if (typeof callback === 'function') {
          window.requestIdleCallback(function() {
            callback();
          });
        } else {
          return undefined;
        }
      } else {
        svs.components.Storage.browser.set(
          key,
          domain,
          payload,
          expires,
          callback
        );
      }
    }

    return {
      isPersonalizationOn: isPersonalizationOn,
      inPersonalizedDomainsKeys: inPersonalizedDomainsKeys,
      clearPersonalizedDomainsKeysFromLS: clearPersonalizedDomainsKeysFromLS,
      personalizedDomainsKeys: personalizedDomainsKeys,
      set: set,
      get: get
    };
  })();
})(svs);


 })(window);