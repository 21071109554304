(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/banner-helpers/assets/javascripts/packages.js') >= 0) return;  svs.modules.push('/components/banner_common/banner-helpers/assets/javascripts/packages.js');

'use strict';

const _excluded = ["boardOptions"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function priceFromOptions(_ref) {
  var _options$jokerOptions, _options$jokerOptions2;
  let {
      boardOptions
    } = _ref,
    options = _objectWithoutProperties(_ref, _excluded);
  const verifyCount = count => Number.isInteger(count) && count > 0 ? count : null;
  const boardOptionsToArray = boards => boards.reduce((arr, board) => {
    let count = verifyCount(board.count) || verifyCount(boardOptions.count) || 1;
    while (count--) {
      arr.push(_objectSpread(_objectSpread({}, boardOptions), board));
    }
    return arr;
  }, []).filter(Boolean);
  let boards = boardOptions.boards;
  if (!Array.isArray(boards) || !boards.length) {
    boards = [{}];
  }
  let price = boardOptionsToArray(boards).reduce((total, board) => {
    var _board$rowPriceMulti;
    let boardPrice = board.rowPrice;
    boardPrice *= (_board$rowPriceMulti = board.rowPriceMulti) !== null && _board$rowPriceMulti !== void 0 ? _board$rowPriceMulti : 1;
    boardPrice *= Boolean(board.hasKungKeno) + 1;
    return total + boardPrice;
  }, 0);
  price *= Boolean(options.hasLotto2) + 1;
  price += (_options$jokerOptions = (_options$jokerOptions2 = options.jokerOptions) === null || _options$jokerOptions2 === void 0 ? void 0 : _options$jokerOptions2.price) !== null && _options$jokerOptions !== void 0 ? _options$jokerOptions : 0;
  return price;
}
const TRISS30_PRICE = 30;
const getMixenData = (mixenPackages, layoutConfig) => {
  var _data$games, _data$tickets;
  if (!layoutConfig.package || !mixenPackages) return {};
  const data = mixenPackages === null || mixenPackages === void 0 ? void 0 : mixenPackages.find(_ref2 => {
    let {
      internalName
    } = _ref2;
    return layoutConfig.package === internalName;
  });
  const priceFromGames = data === null || data === void 0 || (_data$games = data.games) === null || _data$games === void 0 ? void 0 : _data$games.reduce((total, game) => total + (priceFromOptions(game) || 0), 0);
  const hasTicket = (data === null || data === void 0 ? void 0 : data.hasTicket) || (data === null || data === void 0 || (_data$tickets = data.tickets) === null || _data$tickets === void 0 ? void 0 : _data$tickets.length) > 0;
  const priceFromTickets = hasTicket ? TRISS30_PRICE : 0;
  return {
    data: _objectSpread(_objectSpread({}, data), {}, {
      hasTicket
    }),
    price: priceFromGames + priceFromTickets
  };
};
if (svs.isServer) {
  module.exports = {
    getMixenData
  };
} else {
  svs.banner_common.banner_helpers.getMixenData = getMixenData;
}

 })(window);