(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/ticket-card.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/ticket-card.js');
"use strict";


const {
  GridRow
} = svs.ui.ReactGrid;
const {
  getIcon
} = svs.supportern.utils.tools;
const {
  FavIcon,
  HeadToHead,
  Ticket
} = svs.supportern.components;
const {
  Card
} = svs.ui.ReactCard;
const {
  useAtomValue
} = jotai;
const {
  teamAtom,
  drawAtom
} = svs.supportern.atoms;
const {
  DRAW_TYPES
} = svs.supportern.utils.tools;
const TicketCard = _ref => {
  var _team$association, _branding$color;
  let {
    children,
    className = ''
  } = _ref;
  const team = useAtomValue(teamAtom);
  const draw = useAtomValue(drawAtom);
  const {
    branding
  } = (_team$association = team === null || team === void 0 ? void 0 : team.association) !== null && _team$association !== void 0 ? _team$association : {};
  const {
    ownerTeam = team,
    opponentTeam,
    homeGame,
    type
  } = draw !== null && draw !== void 0 ? draw : {};
  const homeTeam = homeGame ? ownerTeam : opponentTeam;
  const awayTeam = homeGame ? opponentTeam : ownerTeam;
  const shouldShowHeadToHead = homeTeam && awayTeam;
  const isEvent = type === 'EVENT';

  const ticketContainerStyle = {
    backgroundColor: (_branding$color = branding === null || branding === void 0 ? void 0 : branding.color) !== null && _branding$color !== void 0 ? _branding$color : '#ffffff'
  };
  let drawAnchor = '';
  if (draw) {
    switch (draw.type) {
      case DRAW_TYPES.MATCH:
        drawAnchor = 'matchspel';
        break;
      case DRAW_TYPES.EVENT:
        drawAnchor = 'eventspel';
        break;
      default:
    }
  }
  return React.createElement(Card, {
    className: "supportern-match-card-ticket ".concat(className),
    id: drawAnchor
  }, React.createElement(React.Fragment, null, isEvent && React.createElement("div", {
    className: "supportern-card-event-banner bg-black fc-white align-center f-550 padding-bottom-half padding-top-half"
  }, "EVENT")), React.createElement("div", {
    className: "supportern-match-card-ticket-fav margin-1"
  }, team && React.createElement(FavIcon, null)), React.createElement(GridRow, null, React.createElement("div", {
    className: "padding-top-2 relative padding-bottom-2 u-flex u-flex-justify-content-center supportern-color-secondary ticket-card-top",
    style: ticketContainerStyle
  }, React.createElement("img", {
    className: "ticket-card-image",
    src: getIcon(branding === null || branding === void 0 ? void 0 : branding.logo)
  }), React.createElement(Ticket, {
    branding: branding,
    draw: draw,
    team: team
  }))), React.createElement("div", {
    className: "relative padding-1 supportern-match-card-footer"
  }, shouldShowHeadToHead && React.createElement("div", {
    className: "margin-top-1"
  }, React.createElement(HeadToHead, {
    awayTeam: awayTeam,
    homeTeam: homeTeam
  })), children));
};
setGlobal('svs.supportern.components.composites.TicketCard', TicketCard);

 })(window);