(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/components/composites/ticket-card-buy.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/components/composites/ticket-card-buy.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useState,
  useEffect,
  useRef
} = React;
const Button = svs.ui.ReactButton;
const {
  GridCol,
  GridRow
} = svs.ui.ReactGrid;
const {
  TicketCount,
  Countdown
} = svs.supportern.components;
const {
  drawAtom,
  paymentMutationAtoms
} = svs.supportern.atoms;
const {
  onChooseGame
} = svs.supportern.utils.tracking;
const {
  TicketCard
} = svs.supportern.components.composites;
const {
  useAtomValue,
  useSetAtom
} = jotai;
const {
  account_services: accountServices,
  dialogs
} = svs.tur.pay;
const {
  moveAnchorToQuery
} = svs.supportern.utils.tools;
const INITIAL_COUNT = 4;
const TicketCardBuy = () => {
  var _svs$utils$paramHandl, _svs$utils$paramHandl2;
  let initCount = INITIAL_COUNT;
  let storedDrawId;
  const mountedRef = useRef(true);
  const draw = useAtomValue(drawAtom);

  const isPay = (_svs$utils$paramHandl = svs.utils.paramHandler.get({
    key: 'pay'
  })) === null || _svs$utils$paramHandl === void 0 ? void 0 : _svs$utils$paramHandl.value;
  const isAfterPay = (_svs$utils$paramHandl2 = svs.utils.paramHandler.get({
    key: 'afterpay'
  })) === null || _svs$utils$paramHandl2 === void 0 ? void 0 : _svs$utils$paramHandl2.value;
  const hasActivePayment = isPay || isAfterPay;
  const localStorageTicketCount = svs.components.Storage.browser.get('ticketcount', 'supportern');
  if (localStorageTicketCount) {
    const {
      count: storageCount = null
    } = localStorageTicketCount[draw.id] || {};
    if (storageCount) {
      initCount = storageCount;
      storedDrawId = draw.id;
    }
  }
  const {
    price: {
      amount: price
    } = {},
    type,
    drawName,
    objective,
    ownerTeam: {
      name
    },
    id
  } = draw;
  const isPlaceholder = !id;
  const [count, setCount] = useState(isPlaceholder ? 0 : initCount);
  const cost = isPlaceholder ? 0 : price * count;
  const mutate = useSetAtom(paymentMutationAtoms);
  useEffect(() => () => {
    mountedRef.current = false;
  }, []);
  const storeTicketCount = function (count) {
    let cb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => {};
    svs.components.Storage.browser.set('ticketcount', 'supportern', _objectSpread(_objectSpread({}, localStorageTicketCount), {}, {
      [draw.id]: {
        count
      }
    }), svs.components.Storage.constants.EXPIRES_10M, () => cb === null || cb === void 0 ? void 0 : cb());
  };
  const setTicketCount = count => {
    storeTicketCount(count, () => setCount(count));
  };
  const handleBuyTicket = () => {
    const totalCost = price * count;
    const confirmPaymentOptions = {
      title: "Vill du k\xF6pa ".concat(count, " ").concat(type.toLowerCase(), "spel f\xF6r ").concat(totalCost, " kr?"),
      messages: ["Observera att ".concat(count > 1 ? 'spelen' : 'spelet', " inte g\xE5r att annullera.")]
    };
    const product = 'supportern';
    let accountBalance;
    const calls = [{
      in: () => {
        var _draw$ownerTeam;
        return {
          price: totalCost,
          product,
          team: draw === null || draw === void 0 || (_draw$ownerTeam = draw.ownerTeam) === null || _draw$ownerTeam === void 0 ? void 0 : _draw$ownerTeam.name
        };
      },
      fn: accountServices.playerSession
    }, {
      in: () => ({
        spelpausFlags: [accountServices.playerSpelpaus.CONSTANTS.ALL]
      }),
      fn: accountServices.playerSpelpaus
    }, {
      fn: accountServices.playerSuspension
    }, {
      fn: accountServices.dayTimeLimit
    }, {
      in: () => ({
        price: totalCost,
        product
      }),
      fn: accountServices.accountBalance,
      out: args => ({
        accountBalance
      } = args)
    }, {
      in: () => _objectSpread({
        price: totalCost,
        accountBalance
      }, confirmPaymentOptions),
      fn: isAfterPay ? _ref => {
        let {
          next
        } = _ref;
        return next();
      } : dialogs.confirmPayment
    }, {
      fn: _ref2 => {
        let {
          next,
          cancel
        } = _ref2;
        return mutate([{
          count,
          draw,
          next,
          cancel
        }]);
      }
    }, {
      in: () => ({
        accountBalance
      }),
      fn: _ref3 => {
        let {
          next
        } = _ref3;
        svs.supportern.utils.payment.showSuccessDialog({
          drawId: id,
          teamName: name,
          count,
          newAccountBalance: svs.utils.format.CurrencyFromJupiterString(accountBalance) - cost,
          isAfterPay
        });
        next();
      }
    }];
    const onCleanup = () => {
      mountedRef.current && setCount(INITIAL_COUNT); 
      svs.supportern.utils.payment.cleanup(draw.id);
      svs.utils.paramHandler.remove({
        key: 'afterpay'
      });
    };
    moveAnchorToQuery();
    svs.tur.pay.initPay({
      calls,
      cancel: () => {
        svs.utils.paramHandler.remove({
          key: 'afterpay'
        });
      },
      done: onCleanup
    });
  };
  if (hasActivePayment && storedDrawId === id) {
    const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
    if (isLoggedIn) {
      handleBuyTicket();
    } else {
      svs.utils.paramHandler.remove({
        key: 'afterpay'
      });
      svs.utils.paramHandler.remove({
        key: 'pay'
      });
    }
  }
  return React.createElement(TicketCard, {
    className: "margin-bottom-1"
  }, isPlaceholder && React.createElement(GridRow, null, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center"
  }, React.createElement("p", {
    className: "padding-4 margin-bottom-0"
  }, "Inget p\xE5g\xE5ende spel"))), type === 'EVENT' && drawName && React.createElement(GridRow, null, React.createElement(GridCol, {
    className: "margin-2 margin-bottom-1 u-flex u-flex-justify-content-center u-flex-wrap u-flex-align-items-center u-flex-column"
  }, React.createElement("div", {
    className: "h3 margin-half f-center"
  }, drawName), React.createElement("div", {
    className: "f-center"
  }, objective))), React.createElement(GridRow, null, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center margin-bottom-1"
  }, React.createElement(Countdown, {
    className: "margin-top-1"
  }))), React.createElement(GridRow, null, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center margin-left-1 margin-right-1 padding-1 u-border-top-1 border-grey-300"
  }, React.createElement("span", {
    className: "f-550"
  }, "K\xF6p spel"))), React.createElement(GridRow, {
    gutter: "0"
  }, React.createElement(GridCol, {
    className: "padding-bottom-2"
  }, React.createElement(TicketCount, {
    count: count,
    handleOnChangeCount: setTicketCount,
    price: price
  }))), React.createElement(GridRow, {
    gutter: "0"
  }, React.createElement(GridCol, {
    className: "u-flex u-flex-justify-content-center"
  }, React.createElement(Button, {
    block: true,
    disabled: !draw.id,
    focus: true,
    onClick: () => {
      onChooseGame(name);
      storeTicketCount(count, handleBuyTicket);
    }
  }, "Betala ", cost, " kr"))));
};
setGlobal('svs.supportern.components.composites.TicketCardBuy', TicketCardBuy);

 })(window);