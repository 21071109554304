(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/utils/anchor-behavior.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/utils/anchor-behavior.js');
"use strict";

const {
  useEffect
} = React;
const paramHandler = svs.utils.paramHandler;
setGlobal('svs.supportern.utils.AnchorBehaviour', _ref => {
  let {
    behavior = 'smooth',
    block = 'center'
  } = _ref;
  useEffect(() => {
    let currentHash = window.location.hash;
    if (!currentHash) {
      currentHash = "#".concat(decodeURIComponent(paramHandler.get({
        key: 'anchor'
      }).value));
    }
    if (currentHash) {
      setTimeout(() => {
        const anchorElement = document.querySelector(currentHash);
        if (anchorElement) {
          anchorElement.scrollIntoView({
            behavior,
            block
          });
        }
      }, 1000);
    }
  }, []);
  return null;
});
svs.supportern.utils.AnchorBehaviour.propTypes = {
  behavior: PropTypes.string,
  block: PropTypes.string
};

 })(window);